.stage {
	margin-top: 48px;
	height: 360px;
	background: url(../../images/header1.jpg); 
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;

	.stage-text {
		margin: 55px auto;
		max-width: 960px;
		text-align: center;
		
	}

	.stage-headline {
		display: block;
		font-size: 75px;
		line-height: 100%;
		text-transform: uppercase;
		color: #fff;
		margin: 0px auto 70px;
		text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
	}
	
	.stage-subline {
		display: block;
		margin: 0 auto;
		font-size: 20px;
		line-height: 150%;
		text-transform: uppercase;
		color: #fff;
		text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
	}
	
	.stage-sign {
		display: block;
		margin: 0 auto;
		font-size: 24px;
		line-height: 150%;
		color: #fff;
		text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
	}
	
	.stage-paragraph {
		display: block;
		font-size: 14px;
		line-height: 150%;
		color: #fff;
		text-transform: uppercase;
		max-width: 580px;
		margin: 0 auto;
		text-shadow: 0px 0px 5px rgba(0,0,0,0.3);
		font-weight: 400;
	}
}

@media only screen and (max-width: 960px) {

	.stage {

		.stage-headline {
			font-size: 52px;
		}
	}
}

@media only screen and (max-width: 800px) {

	.stage {

		.stage-headline {
			font-size: 52px;
		}
	}
}